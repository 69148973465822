import 'core-js/stable'
import 'regenerator-runtime/runtime'
import 'common/utils/dom'
import 'common/utils/css'

import 'bootstrap/dist/js/bootstrap.bundle.min'
import '../style/app.scss'

import * as Sentry from '@sentry/react'

window.Kernel = class {
    static boot(release, sentryDsn, environment, googleMapsApiKey, user) {
        Kernel.release = release
        Kernel.sentryDsn = sentryDsn
        Kernel.user = user ? { email: user } : null

        let app = false

        const runIfReady = () => {
            if (app) {
                const { sentryDsn, release, listeners, user } = Kernel

                if (sentryDsn) {
                    Sentry.init({
                        dsn: sentryDsn,
                        release,
                        integrations: [Sentry.browserTracingIntegration()],
                        tracesSampleRate: 0.025,
                    })

                    if (user) {
                        Sentry.setUser(user)
                    }
                }

                for (const i in listeners) {
                    App.addListener(listeners[i])
                }

                App.run({
                    sentryDsn,
                    release,
                    environment,
                    googleMapsApiKey,
                })
            }
        }

        const handleError = (error) => {
            throw error
        }

        import('./app').catch(handleError).then(() => {
            app = true
            runIfReady()
        })
    }

    static onLoad(callback) {
        Kernel.listeners.push(callback)
    }
}

Kernel.release = null
Kernel.sentryDsn = null
Kernel.listeners = []
